import React, { useEffect } from "react";

import { OneTap, WidgetEvents, OneTapInternalEvents } from "@vkid/sdk";

export const VkOneTapButton = React.forwardRef((props, ref) => {
    useEffect(() => {
        if (ref.current) {
            const oneTap = new OneTap();

            oneTap
                .render({
                    container: ref.current,
                    styles: {
                        borderRadius: 20,
                        height: 55,
                    },
                })
                .on(WidgetEvents.ERROR, console.error);
        }
    }, []);

    return <></>;
});
