import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Container, Skeleton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import useActiveSale from "hooks/useActiveSale.js";
import { _getPlatform, _isMobile, _isObjEmpty } from "./helpers.js";
import clsx from "clsx";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../css/banners.css";

export default function Footer() {
    const { openSaleFromLink } = useActiveSale();

    const platform = _getPlatform();

    const banners = useSelector(({ banners }) => {
        if (_isMobile()) {
            if (
                platform === "vk" &&
                banners?.vkMobileBanners?.banners?.length
            ) {
                return banners.vkMobileBanners;
            } else if (
                (platform === "android" || platform === "ios") &&
                banners?.mobileAppBanners?.banners?.length
            ) {
                return banners.mobileAppBanners;
            } else {
                return banners?.mobileBanners;
            }
        } else {
            if (platform === "vk" && banners?.vkBanners?.banners?.length) {
                return banners.vkBanners;
            } else {
                return banners?.banners;
            }
        }
    });

    const [swiper, setSwiper] = useState();

    const isValidUrl = (string) => {
        try {
            return Boolean(new URL(string));
        } catch (e) {
            return false;
        }
    };

    const handleBannerClick = useCallback((banner) => {
        if (banner.newTab) {
            window.open(banner.link, "_blank");
        } else {
            if (!isValidUrl(banner.link) && banner.link.includes("?sale_id=")) {
                openSaleFromLink(banner.link);
            } else {
                window.location.href = banner.link;
            }
        }
    }, []);

    if (!banners?.banners?.length) {
        return null;
    }

    let swiperProps = {
        pagination: true,
        slidesPerView: 1,
        modules: [Autoplay, Pagination],
    };
    if (_isMobile()) {
        swiperProps.spaceBetween = 15;
        if (banners.autoplay) {
            swiperProps.autoplay = {
                delay: banners.autoplay,
            };
        }
    } else {
        swiperProps.modules.push(Navigation);
        swiperProps.navigation = true;
        if (banners.autoplay) {
            swiperProps.autoplay = {
                delay: banners.autoplay,
            };
        }
        swiperProps.spaceBetween = 16;
    }

    return (
        <>
            <div
                className={
                    _isMobile() ? "mobile-banners-wrapper" : "banners-wrapper"
                }
            >
                <Container
                    sx={{
                        maxWidth: window.fullWidthSite
                            ? "1328px !important"
                            : "auto",
                    }}
                >
                    <Swiper
                        {...swiperProps}
                        className={clsx(
                            _isMobile()
                                ? "bannersMobile-swiper"
                                : "banners-swiper"
                        )}
                        onSwiper={(swiper) => setSwiper(swiper)}
                        loop={true}
                        loopAdditionalSlides={1}
                    >
                        {!_isMobile()
                            ? banners.banners.map((banner, key) => (
                                  <SwiperSlide
                                      key={key}
                                      className="banner-slide"
                                  >
                                      {({ isPrev, isNext }) => {
                                          return (
                                              <div className="banner-slide--image-container">
                                                  <img
                                                      src={banner.img}
                                                      alt={banner.alt}
                                                      style={{
                                                          cursor: banner.link
                                                              ? "pointer"
                                                              : "auto",
                                                      }}
                                                      onClick={() => {
                                                          if (isNext) {
                                                              swiper.slideNext();
                                                          } else if (isPrev) {
                                                              swiper.slidePrev();
                                                          } else if (
                                                              banner.link
                                                          ) {
                                                              handleBannerClick(
                                                                  banner
                                                              );
                                                          }
                                                      }}
                                                  />
                                              </div>
                                          );
                                      }}
                                  </SwiperSlide>
                              ))
                            : banners.banners.map((banner, key) => (
                                  <SwiperSlide
                                      key={key}
                                      className="banner-slide"
                                  >
                                      <div className="banner-slide--image-container">
                                          {banner.link ? (
                                              <img
                                                  src={banner.img}
                                                  alt={banner.alt}
                                                  style={{
                                                      cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                      handleBannerClick(banner)
                                                  }
                                              />
                                          ) : (
                                              <img
                                                  src={banner.img}
                                                  alt={banner.alt}
                                              />
                                          )}
                                      </div>
                                  </SwiperSlide>
                              ))}
                    </Swiper>
                </Container>
            </div>
        </>
    );
}
