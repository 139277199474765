import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import useTownNavigate from "hooks/useTownNavigate";
import { ChooseTownPage, Home } from "../pages";
import { getPagesList } from "./getPgesList";
import App from "../App";

const AppRouter = () => {
    const { pathname, search } = useLocation();
    const { navigateTo } = useTownNavigate();

    const savedTownId = useSelector(
        (state) => state.config?.data?.currentTownId
    );

    const savedTown = window.towns?.find((town) => town.id == savedTownId);

    const foldersRouting = window.townsTypeRoute === "folder";

    const isDomainChooseTown = window.CONFIG_main_site_choose_town === "on";

    // Редиректим со страницы выбора города, если ранее уже был выбран город
    useEffect(() => {
        if (foldersRouting && savedTown && pathname === "/") {
            navigateTo(`/${savedTown.slug}`, {}, search);
        }
    }, []);

    return (
        <Routes>
            {/* Если основной домен это выбор города или сайт на подпапках, то корневой путь это страница выбора города */}
            {isDomainChooseTown || foldersRouting ? (
                <Route exact path="/" element={<ChooseTownPage />} />
            ) : null}

            {/* Если основной домен это выбор города, то всё редиректим на выбор города  */}
            {isDomainChooseTown ? (
                <Route exact path="*" element={<Navigate to="/" replace />} />
            ) : null}

            {/* Если основной домен это выбор города, то остальное приложение на этом домене не рендерится */}
            {isDomainChooseTown ? null : (
                <Route element={<App />}>
                    {foldersRouting ? (
                        <Route exact path="/:town" element={<Home />} />
                    ) : (
                        <Route exact path="/" element={<Home />} />
                    )}

                    {foldersRouting ? (
                        <Route path="/:town">
                            {getPagesList().map(({ path, element }, index) => (
                                <Route
                                    path={path}
                                    element={element}
                                    key={index}
                                />
                            ))}
                        </Route>
                    ) : (
                        <>
                            {getPagesList().map(({ path, element }, index) => (
                                <Route
                                    path={path}
                                    element={element}
                                    key={index}
                                />
                            ))}
                        </>
                    )}
                </Route>
            )}
        </Routes>
    );
};

export default AppRouter;
