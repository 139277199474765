import React, { useState, useEffect } from "react";

import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bridge from "@vkontakte/vk-bridge";

import { faVk } from "@fortawesome/free-brands-svg-icons";

import "css/vk-app-auth-button.css";

export const VkAppAuthButton = () => {
    const [userFirstName, setUserFirstName] = useState("");

    useEffect(() => {
        bridge
            .send("VKWebAppGetUserInfo")
            .then((data) => {
                console.log(data);

                if (data.first_name) {
                    setUserFirstName(data.first_name);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleLogin = () => {
        bridge
            .send("VKWebAppGetPhoneNumber")
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <Button
            onClick={handleLogin}
            variant="contained"
            className="vk-app-auth-button"
        >
            <FontAwesomeIcon icon={faVk} />
            {userFirstName
                ? `Продолжить как ${userFirstName}`
                : "Войти с помощью VK"}
        </Button>
    );
};
