import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRecommendProducts } from "redux/selectors/productSelectors";
import { addProductToCart } from "redux/actions/cart";
import { setModalProduct, setOpenModal } from "redux/actions/productModal";

import { useLocation } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper";
import MiniCartReccomendProduct from "components/Product/MiniCartReccomendProduct";
import AddonProductMini from "components/Product/AddonProductMini";
import { ClickAwayListener, Portal } from "@mui/material";

import { _isCategoryDisabled } from "components/helpers";
import useMetricFunctions from "hooks/useMetricFunctions";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import addonImg from "img/addons.jpg";

export default function MiniCartReccomends({ minicartInner }) {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { metricViewProduct, metricAddProductToCart } = useMetricFunctions();

    const [miniCartAddonOpen, setMiniCartAddonOpen] = React.useState(false);
    const [miniCartAddonOpenFirst, setMiniCartAddonOpenFirst] =
        React.useState(true);

    const recommendProducts = useSelector(getRecommendProducts);
    const addon_items = useSelector(({ products }) => products.addon_items);
    const isMiniCartAddonsHidden = useSelector(
        (state) =>
            state.config.data.CONFIG_basket_hide_category_addons === "active"
    );

    if (!recommendProducts) {
        return null;
    }

    const handleOpenAddonModal = () => {
        if (pathname === "/cart") document.body.style.overflow = "initial";

        setMiniCartAddonOpen(true);
        setMiniCartAddonOpenFirst(false);
    };

    const handleCloseAddonModal = () => {
        if (pathname === "/cart") document.body.style.overflow = "hidden";
        setMiniCartAddonOpen(false);
    };

    const onProductClick = (product) => {
        if (product.type === "variations") {
            let url = new URL(window.location.href);
            if (!url.searchParams.has("product_id")) {
                url.searchParams.append("product_id", product.id);
                window.history.pushState({}, "", url.href);
            }
            metricViewProduct(product);
            dispatch(
                setModalProduct({
                    ...product,
                })
            );
            dispatch(setOpenModal(true));
        } else {
            metricAddProductToCart(product);
            dispatch(addProductToCart(product));
        }
    };

    return (
        <div>
            <div className="minicart-recommends">
                <h4 className="minicart--recommends-title">
                    Рекомендуем к заказу
                </h4>

                <Swiper
                    slidesPerView="auto"
                    spaceBetween={16}
                    freeMode={true}
                    navigation={true}
                    modules={[FreeMode, Navigation]}
                >
                    {addon_items.length && !isMiniCartAddonsHidden ? (
                        <SwiperSlide className="reccomend-slide reccomend-addons-slide reccomend-slide--mini">
                            <div
                                className="reccomend-slide--addons-wrapper recommend-product"
                                onClick={handleOpenAddonModal}
                            >
                                <img
                                    className="lazyload-image"
                                    src={addonImg}
                                    alt={"Дополнения"}
                                />
                                <div className="reccomend-slide--addons-title">
                                    Дополнения
                                </div>
                            </div>
                        </SwiperSlide>
                    ) : null}

                    {recommendProducts.map((product) => (
                        <SwiperSlide
                            key={product.id}
                            className="reccomend-slide reccomend-slide--mini"
                        >
                            <MiniCartReccomendProduct
                                product={product}
                                onProductClick={onProductClick}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            {addon_items.length &&
            miniCartAddonOpen &&
            minicartInner.current ? (
                <Portal container={minicartInner.current}>
                    <div
                        className={`minicart--addons-backdrop ${
                            miniCartAddonOpen && "active"
                        } ${miniCartAddonOpenFirst && "no-animate"}`}
                    >
                        <ClickAwayListener onClickAway={handleCloseAddonModal}>
                            <div
                                className={`minicart--addon-products-modal ${
                                    miniCartAddonOpen && "active"
                                } ${miniCartAddonOpenFirst && "no-animate"}`}
                            >
                                <IconButton
                                    color="inherit"
                                    onClick={handleCloseAddonModal}
                                    className="minicart--addon-products__minicart-close"
                                >
                                    <CloseIcon />
                                </IconButton>

                                <div className="minicart--addon-products__minicart-title">
                                    Соусы и дополнения
                                </div>

                                <div className="popup-addons-wrapper">
                                    {addon_items.map((product) => (
                                        <div key={product.id}>
                                            <AddonProductMini
                                                key={product.id}
                                                product={product}
                                                miniCartAddonOpen={
                                                    miniCartAddonOpen
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </ClickAwayListener>
                    </div>
                </Portal>
            ) : null}
        </div>
    );
}
