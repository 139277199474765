import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopSalesProducts } from "redux/selectors/productSelectors";
import { setModalProduct, setOpenModal } from "redux/actions/productModal";

import MiniCartReccomendProduct from "./Product/MiniCartReccomendProduct";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper";

import useMetricFunctions from "hooks/useMetricFunctions";
import { Container } from "@mui/material";

const HomeRecommends = () => {
    const dispatch = useDispatch();
    const { metricViewProduct } = useMetricFunctions();

    const recommendProducts = useSelector(getTopSalesProducts);
    const title = useSelector(
        (state) => state.config.data.CONFIG_top_sales_title
    );

    if (!recommendProducts) {
        return null;
    }

    const onProductClick = (product) => {
        let url = new URL(window.location.href);
        if (!url.searchParams.has("product_id")) {
            url.searchParams.append("product_id", product.id);
            window.history.pushState({}, "", url.href);
        }
        metricViewProduct(product);
        dispatch(
            setModalProduct({
                ...product,
            })
        );
        dispatch(setOpenModal(true));
    };

    return (
        <Container>
            <div className="home-recommends">
                <h2 className="home-recommends--title">
                    {title || "Часто заказывают"}
                </h2>
                <Swiper
                    slidesPerView="auto"
                    spaceBetween={16}
                    freeMode={true}
                    navigation={true}
                    modules={[FreeMode, Navigation]}
                >
                    {recommendProducts.map((product) => (
                        <SwiperSlide
                            key={product.id}
                            className="reccomend-slide reccomend-slide--mini"
                        >
                            <MiniCartReccomendProduct
                                product={product}
                                onProductClick={onProductClick}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </Container>
    );
};

export default HomeRecommends;
