import React, { useEffect } from "react";

import {
    FloatingOneTap,
    WidgetEvents,
    FloatingOneTapInternalEvents,
} from "@vkid/sdk";
import { useSelector } from "react-redux";
import { _getDomain, _getPlatform } from "./helpers";

export const VkFloatingOneTapButton = () => {
    const config = useSelector((state) => state.config.data);
    const user = useSelector((state) => state.user.user);

    useEffect(() => {
        let url = new URL(window.location.href);

        console.log(url.searchParams.has("code"));

        if (
            config.CONFIG_auth_vk_popup === "active" &&
            !user.token &&
            !url.searchParams.has("code")
        ) {
            const floatingOneTap = new FloatingOneTap();

            floatingOneTap
                .render({
                    appName: "APP_NAME",
                    contentId: 5,
                    responseMode: "",
                })
                .on(WidgetEvents.ERROR, console.error)
                .on(FloatingOneTapInternalEvents.LOGIN_SUCCESS, (payload) => {
                    floatingOneTap.close();
                });
        }
    }, []);
    return <></>;
};
